let grid = document.querySelector('.story-list-items');
let selectBox = document.querySelector('.js-change-category-select');
document.addEventListener('change', function (event) {
    if (!event.target.matches('.js-change-category-select')) return;
    event.preventDefault();

    let selectedValue = selectBox.value;

    if (!selectBox.classList.contains('loading')) {
        selectBox.classList.add('loading');
        let pageUrl = event.target.getAttribute('data-url');
        if (selectedValue) {
            pageUrl = pageUrl + '?lokalita=' + selectedValue;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('get', pageUrl);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();

        xhr.onloadend = function (response) {
            if (response.target.status === 0) {
                console.log('undefined error');
                // Failed XmlHttpRequest should be considered an undefined error.
            } else if (response.target.status === 400) {
                console.log('Bad Request');
                // Bad Request
            } else if (response.target.status === 200) {
                const result = JSON.parse(response.target.response);
                grid.innerHTML = result.html;
            }
            selectBox.classList.remove('loading');
        };
    }
    return false;
}, false);
